/* eslint-disable @typescript-eslint/no-explicit-any */
import { Popover } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import {
	ListingKind,
	SUPPORTED_NON_DAPPER_TOKENS,
	SUPPORTED_TOKEN_LIST,
} from "flowty-common"
import React, { useEffect, useMemo } from "react"
import { AccountSummaries, AccountSummary } from "../Types/GlobalTypes"
import { FlowtyAccountTag } from "./components/FlowtyAccountTag"
import fallbackImage from "../assets/fallBack.png"
import { Flowty } from "flowty-sdk"

interface WalletSelectorProps<T> {
	label: string
	selected?: AccountSummary | null
	selectorFilter?: SelectorFilter<T>
	setSelected?: (account: AccountSummary) => void
	disabledMessage?: string
	description?: string
	summaries: AccountSummaries | undefined
	listingType: "loan" | "rental" | "storefront" | "transfer" | "acceptOffer"
}

export interface SelectorFilter<T> {
	apply: (account: AccountSummary) => boolean
	params: T
}

export class PassThroughFilter implements SelectorFilter<any> {
	params: any

	constructor() {
		this.params = {}
	}

	apply(_: AccountSummary): boolean {
		return true
	}
}

export class ValidTokenReceiverFilter
	implements SelectorFilter<{ listingType: ListingKind }>
{
	params: { listingType: ListingKind; flowty: Flowty }

	constructor(params: { listingType: ListingKind; flowty: Flowty }) {
		this.params = params
	}

	apply(account: AccountSummary): boolean {
		const availableTokens =
			this?.params?.listingType === "storefront"
				? SUPPORTED_TOKEN_LIST
				: SUPPORTED_NON_DAPPER_TOKENS

		for (let i = 0; i < availableTokens.length - 1; i++) {
			const token = availableTokens[i]
			if (
				!!account?.tokens?.[this.params.flowty.tokens.getTokenIdentifier(token)]
					?.receiverPath ||
				account.isMain
			)
				return true
		}
		return false
	}
}

export class TokenProviderFilter implements SelectorFilter<TokenNameParams> {
	params: TokenNameParams

	constructor(params: TokenNameParams) {
		this.params = params
	}

	apply(account: AccountSummary): boolean {
		return (
			(account?.tokens?.[this.params.tokenIdentifier]?.providerPaths?.length ||
				0) > 0
		)
	}
}

export class NftReceiverFilter implements SelectorFilter<string[]> {
	params: string[]

	constructor(params: string[]) {
		this.params = params
	}

	apply(account: AccountSummary): boolean {
		return this.params.includes(account.address)
	}
}

export interface TokenNameParams {
	tokenIdentifier: string
	listingType?: ListingKind
}

export const FlowtyWalletSelector: React.FC<WalletSelectorProps<any>> = ({
	selected,
	setSelected,
	selectorFilter = new PassThroughFilter(),
	label,
	disabledMessage,
	description,
	summaries,
	listingType,
}) => {
	const accounts = useMemo(() => {
		return Object.values(summaries || {}).reduce(
			(result, account: AccountSummary) => {
				const invalidDapper =
					account?.isDapper &&
					(listingType === "loan" ||
						listingType === "rental" ||
						listingType === "acceptOffer")
				const isAccountInvalid =
					(!selectorFilter?.apply(account) && !account.isMain) || invalidDapper

				return isAccountInvalid
					? { invalid: [account, ...result.invalid], valid: result.valid }
					: { invalid: result.invalid, valid: [account, ...result.valid] }
			},
			{
				invalid: [] as AccountSummary[],
				valid: [] as AccountSummary[],
			}
		)
	}, [summaries, selectorFilter, listingType])

	useEffect(() => {
		const isSelectedValid = accounts.valid.reduce(
			(isValid, account) => selected?.address === account.address || isValid,
			false
		)

		if (!isSelectedValid) {
			setSelected?.(accounts.valid[0])
		}
	}, [selectorFilter.params, accounts, listingType, summaries])

	function addFallbackImage(e: any): void {
		const modifiedEvent = { ...e }
		modifiedEvent.target.src = fallbackImage
	}

	if (!selected || !setSelected || Object.values(summaries || {}).length <= 1)
		return null
	return (
		<div
			className='w-full flex flex-col justify-between gap-3'
			data-testid='wallet-selector'
		>
			<p
				className='text-base font-semibold text-white'
				data-testid='wallet-selector-title'
			>
				{label}
			</p>
			<Popover className='relative w-full'>
				<Popover.Button
					className='w-full'
					data-testid='wallet-selector-btn-open'
				>
					{({ open }) => (
						<div
							className={`flex gap-2 font-bold font-montserrat-bold bg-[#606E7D33] border border-[#FFFFFF1F] hover:bg-[#606E7D4D] rounded-md px-1 md:px-3 py-1 items-center justify-around md:justify-between group text-[#F8F9FA] ${
								open && "border-[#FFFFFF7A]"
							}`}
						>
							<div className='flex items-center justify-between w-full'>
								<div className='flex gap-1 items-center whitespace-nowrap w-4/5'>
									<img
										onError={addFallbackImage}
										src={selected?.display?.thumbnail || ""}
										alt='Wallet Icon'
										className='h-6 w-6 content-fit rounded-md'
									/>
									<span className='p-1 font-bold font-montserrat-bold text-nowrap text-white overflow-hidden text-ellipsis'>
										{selected?.display?.name || selected.address || ""}
									</span>
								</div>
								<FlowtyAccountTag isMainWallet={selected.isMain} />
							</div>
							<ChevronDownIcon
								className={`h-7 w-7 text-white transition-transform duration-300  ${
									open && "rotate-180 transform"
								}`}
							/>
						</div>
					)}
				</Popover.Button>
				{/* Dropdown items*/}
				<Popover.Panel
					data-testid='wallet-selector-panel'
					className='absolute z-10 bg-[#232D39] rounded-md mt-2 p-2 text-[12px] border border-[#FFFFFF7A] overflow-hidden w-full'
				>
					{accounts?.valid.map(account => {
						return (
							<Popover.Button
								as='div'
								key={account.address}
								onClick={() => {
									setSelected(account)
								}}
								className={`cursor-pointer w-full px-3 py-1 hover:bg-[#6C757D40] hover:text-white flex justify-between items-center transition-all ease-in-out duration-300 rounded-md`}
								data-testid='wallet-selector-wallet-item'
							>
								<div className='flex items-center whitespace-nowrap w-4/5'>
									<img
										onError={addFallbackImage}
										src={account?.display?.thumbnail}
										alt='Wallet Icon'
										className='h-6 w-6 content-fit rounded-md'
									/>
									<span className='p-1 md:p-2 font-bold font-montserrat-bold text-nowrap text-white overflow-hidden text-ellipsis'>
										{account?.display?.name || account?.address || ""}
									</span>
								</div>
								<FlowtyAccountTag isMainWallet={account.isMain} />
							</Popover.Button>
						)
					})}

					{accounts.invalid.map((account, index) => {
						return (
							<div key={account.address}>
								<Popover.Button
									aria-disabled
									as='div'
									onClick={() => {
										setSelected(account)
									}}
									className={`cursor-not-allowed w-full px-3 py-1 flex items-center justify-between opacity-50`}
									data-testid='wallet-selector-wallet-item'
								>
									<div className='flex items-center'>
										<img
											onError={addFallbackImage}
											src={account?.display?.thumbnail}
											alt='Wallet Icon'
											className='h-7 w-7 md:h-8 md:w-8 content-fit rounded-md'
										/>
										<span className='p-1 md:p-2 font-bold font-montserrat-bold text-nowrap text-white overflow-hidden text-ellipsis'>
											{account?.display?.name || account?.address || ""}
										</span>
									</div>
									<FlowtyAccountTag isMainWallet={account.isMain} />
								</Popover.Button>
								{index === accounts.invalid.length - 1 && disabledMessage ? (
									<div className='w-full border-t border-gray-500 h-8 text-danger text-xs grid place-items-center'>
										{disabledMessage}
									</div>
								) : null}
							</div>
						)
					})}
				</Popover.Panel>
			</Popover>
			{description && <div className='text-sm py-1'>{description}</div>}
		</div>
	)
}

export const walletDisabledMessage = {
	payTo: "Disabled wallets not setup to receive token type.",
	payWith: "Disabled wallets may not have token provider setup.",
	sendTo: "Disabled wallets may not be setup to receive NFT",
}
