import { getMessageFromError } from "./typeUtilities"

export class FlowtyException extends Error {
	public error: Error

	public context: { [key: string]: any }

	public origin: string

	constructor(error: Error, context: { [key: string]: any }, origin: string) {
		const message = getMessageFromError(error)
		super(
			`${origin || "unknown origin"} ${
				message || "unknown error message"
			} ${JSON.stringify(context)} `
		)
		this.error = error
		this.context = context
		this.origin = origin
	}
}

export const ERROR_BIGGER_THAN_0 = "BIGGER_THEN_0"
export const ERROR_ONLY_TWO_DECIMAL_PLACES = "ONLY_TWO_DECIMAL_PLACES_ALLOWED"
